import 'select2'
import 'global/lib/select2-searchInputPlaceholder'

export default class ibDesktopLibSelect2 {
  constructor(container) {
    this.select2 = container.find('[data-select="select2"]')
    this.select2Placeholder = this.select2.data('placeholder')
    this.select2float = container.find('[data-for-float-select2]')
    this.select2floatPlaceholder = this.select2float.data('placeholder')
    this.select2Applied = this.select2.hasClass('select2-hidden-accessible')
    this.select2floatApplied = this.select2float.hasClass('select2-hidden-accessible')

    document.addEventListener('turbolinks:load', this.selectInit)
    this.selectInit()
  }

  selectInit() {

    try {
      if (this.select2 && this.select2Applied) this.select2.select2('destroy')
      if (this.select2float && this.select2floatApplied) this.select2float.select2('destroy')
    } catch (e) {
      console.warn('Select2 could not be destroyed', e)
    }

    const applied = this.select2?.select2({
      theme: 'theme',
      width: '100%',
      placeholder: this.select2Placeholder,
      dropdownParent: this.select2.parent(),
      minimumResultsForSearch: 11,
      containerCssClass: 'form-control form-control-lg',
      templateResult: this.wrapSelection,
      templateSelection: this.wrapSelection,
      searchInputPlaceholder: 'Tìm kiếm nhanh'
    })

    applied.closest('.form-group.select').addClass('select2--applied')

    applied.on('select2:select', e => {
      const select = applied.get(0)
      const evt = new Event('selected:synt')
      select.getElementsByTagName('option')[e.target.selectedIndex].selected = 'selected'
      select.dispatchEvent(evt)
    })

    this.select2float?.select2({
      theme: 'float',
      width: '100%',
      placeholder: this.select2floatPlaceholder,
      minimumResultsForSearch: 11,
      containerCssClass: 'form-control form-control-lg',
      searchInputPlaceholder: 'Tìm kiếm nhanh'
    }).closest('.form-group.select').addClass('select2--applied')
  }

  wrapSelection(state) {
    if ($(state.element).data('logoUrl')) {
      const span = document.createElement('span')
      const img = document.createElement('img')
      span.className = 'bank__name'
      img.className = 'bank__logo_image'
      img.src = $(state.element).data('logoUrl')
      span.append(img, state.text)
      return span
    } else {
      return state.text
    }
  }
}
